
import './css/App.css';
import './css/largeScreen.css';
import HeroBg from './images/hero-bg.png';
import bottom from './images/bottom-bg.png';
import input from './images/input.svg';
import innovating from './images/innovating.svg';
import ciamDesc from './images/CIAM-desctop.png';
import ciamMob from './images/CIAM-mobile.png';
import RCS1 from './images/RCS1.png';
import RCS2 from './images/RCS2.png';

import handleClick from './components/showHide.jsx';

function App() {

  return (
    <div className='content'>
      <div className='head'>
        <img src={HeroBg} alt='A koi fish' className='headImage'></img>
        <div className='heading'>I transform complexity into clarity,<br></br>
          <span className='bread'> and establish a common vision through open communication and teamwork.</span>
        </div>
      </div>
      <div className='message'>
        <p>My long time goal is to create a lasting, engaging and positive work environment. </p>
        <p>Transparent vision creates opportunities for innovation and collaborated work environment lead to a growing business.</p>
        <p>Individual wishes and needs are identified though open dialog, and blockers are analyzed for a meaningful ways forward. </p>
    </div>
  
  <div className='wraper'>  
    <div className='grid-info'>
        <div className='information employment'>
          <p className='sub-intro'>Employment</p>
          <p className='sub-head'>SOGETI</p>
          <p className='info-bread'>Lead designer and product design</p>
          <p className='sub-head'> TEDKOMP</p>
          <p className='info-bread'>Lead product design</p>
          <p className='sub-head'>Freelance consultant</p>
          <p className='info-bread'>Idealization and digital product development</p>
          <p className='sub-head'>Indego – advertising agency</p>
          <p className='info-bread'>Final artist and web designer</p>           
        </div>

        <div className='information volunteer'>
            <p className='sub-intro'>Volunteer</p>
            <p className='info-bread'>Mentor – UX design students</p>
            <p className='info-bread'>Board member –  UX education</p>
            <p className='info-bread'>Certified Ski instructor</p>
        </div>

        <div className='information consultant'>
          <p className='sub-intro'>Assignments</p>
          <p className='sub-head'>Inter IKEA -IMC</p>
            <p className='info-bread'>Performed product discovery, focusing on the needs and preferences of users.  Insight on how to prioritize and organize functionality for project  oversight.</p>
            <p className='sub-head'>H&M Business tech</p>
            <p className='info-bread'>Modernize digital legacy tools for store and product management, focus on quality assurance and stakeholder collaboration.</p>
            <p className='sub-head'>Ingka IKEA – IT AB</p>
            <p className='info-bread'>Product visual and interaction alignment. Established a comprehensive understanding of its functionality, ensuring a clear and efficient user  experience.</p>
            <p className='sub-head'>Sony mobile</p>
            <p className='info-bread'>Explored and guided in innovation of extended phone/contact book/message functionality. Contributed to 3D scanning, mobile emergency functionality, and contact security.</p>
        </div>  

        <div className='information numbers'>
          <div className='sub-intro'>Impact</div>
            <p>Grew UX organization with 200%</p>
            <p>Increased unique identified users with 128% from 7 milion to 9 milion</p>
            <p>Increased unique visitors per month with 320% and a return rate of 80%</p>
            <p>Introduced advanced phone and contact features to 50milion daily users</p>
            {/* <p> omnichannel alignment</p> */}
          </div>
        </div>

        <img src={bottom} className='seaFloor' alt='sea floor background'></img>
     </div>

     <div className='process'>
        <div className='process-info progress-grid'> 
              <div id='bv' onClick={handleClick} className='bv grid-right'> Business value</div>
                {/* <div  className='bv-info hidden'> test Business value
                      Text
                      image
                <div className='close'>close</div>
                </div> */}
              <div id='un' onClick={handleClick} className='un grid-right'>User Needs</div>
                  {/* <div className='un-info hidden'> test User needs
                      Text
                      image
                      <div className='close'>close</div>
                  </div> */}
              <div id='ea' onClick={handleClick} className='ea grid-left'>Environmental analysis</div>
              <div id='vision' onClick={handleClick} className='vision grid-right'>Vision</div>
              <div id='se' onClick={handleClick} className='se grid-left'>Success expectations</div>
              <div id='tp' onClick={handleClick} className='tp grid-left'>Technical possibilities</div>
              <img src={input} alt='input' className='input-img'></img>
          </div>
          <div className='Innovating-info innovation-grid'> 
              <div id='sketch' onClick={handleClick} className='sketch grid-right'>Sketch</div>
              <div id='bp' onClick={handleClick} className='bp grid-right'>BluePrints</div>
              <div id='ui' onClick={handleClick} className='ui grid-right'>UI</div>
              <div id='uj' onClick={handleClick} className='uj grid-left'>User journey</div>
              <div id='stratergy' onClick={handleClick} className='stratergy grid-left'>Stratergy</div>
              <img src={innovating} alt='innovating' className='innovating-img'></img>
          </div>
     </div>
      <div className='result'>
            <div className='ciam-grid result-info'>
                  <img src={ciamDesc} alt='input' className='ciam-desctop'></img>
                  <img src={ciamMob} alt='input' className='ciam-mobile'></img>
                    <div className='ciamInfo'>
                      <span className='BoldText'>Purpose: </span> 
                      <span className='bread'>Deliver omnichannel experience for customer identification online.</span>
                    <br></br>
                    <br></br>
                    <span className='BoldText'>Result: </span> 
                    <span className='bread'>Increase with 2 million new unique users.</span>
                    </div>
            </div>
      </div>

      <div className='result'>
            <div className='rcs-grid result-info'>
                  <img src={RCS1} alt='input' className='RCS1'></img>
                  <img src={RCS2} alt='input' className='RCS2'></img>
                    <div className='RCSInfo'>
                      <span className='BoldText'>Purpose: </span> 
                      <span className='bread'>Introduce Rich Contact Services for Sony-experia users.</span>
                    <br></br>
                    <br></br>
                    <span className='BoldText'>Result: </span> 
                    <span className='bread'>Advanced phone features presented to 50 milion daily users</span>
                    </div>
            </div>
            <div className='contact'>
              <div className='rcs-grid result-info'>
                  <span>Contact me on <a href='https://www.linkedin.com/in/philip-tragardh/' target='blank'> LinkedIN</a></span>
              </div>
            </div>
      </div>
   </div>
  );
}

export default App;
